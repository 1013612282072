<template>
    <n-modal
        :show="true"
        :mask-closable="false"
        preset="dialog"
        :show-icon="false"
        :closable="false"
    >
        <template #header>
            <h2 class="mb-0 mt-0">{{ person }} hinzufügen</h2>
        </template>
        <template #default>
            <n-space class="pb-4" vertical>
                <p>Bitte gebe unten den Namen ein.</p>
                <n-input-group>
                    <n-input-group-label>{{ emoji }}</n-input-group-label>
                    <n-input v-model:value="name" type="text" placeholder="Name" block />
                </n-input-group>
            </n-space>
        </template>

        <template #action>
            <n-grid x-gap="12" :cols="4">
                <n-gi span="1">
                    <n-button large type="default" block @click="closeDialog">
                        Abbrechen
                    </n-button>
                </n-gi>
                <n-gi span="2" offset="1">
                    <n-button large type="success" block @click="send">
                        <template #icon>
                            <n-icon>
                                <person-add-icon/>
                            </n-icon>
                        </template>
                        {{ person }} hinzufügen
                    </n-button>
                </n-gi>
            </n-grid>

        </template>
    </n-modal>
</template>
<script>
import {defineComponent, getCurrentInstance, ref} from "vue"
import {mapGetters} from "vuex"
import {NModal, NButton, NIcon, NGrid, NGi, NSpace, NInput, NInputGroup, NInputGroupLabel} from "naive-ui"
import {
    PersonAdd as PersonAddIcon,
} from '@vicons/ionicons5'

import Api from '../../../api/Api'

import AbstractNaiveUiDialog from "./AbstractNaiveUiDialog";

export default defineComponent({
    extends: AbstractNaiveUiDialog,
    setup(props) {
        const {emit} = getCurrentInstance()
        const name = ref(null)
        const closeDialog = () => {
            emit('resetDialog')
        }
        return {
            name,
            closeDialog,
        }
    },
    props: {
        openedDialog: {
            type: String,
            required: true,
        }
    },
    components: {
        NModal,
        NButton,
        NIcon,
        NGrid,
        NGi,
        NSpace,
        NInput,
        NInputGroup,
        NInputGroupLabel,
        PersonAddIcon,
    },
    computed: {
        ...mapGetters(['getCurrentTeam', 'getTeamId']),
        person() {
            return (this.getCurrentTeam) ? this.getCurrentTeam.person_single : 'unknown'
        },
        emoji() {
            return (this.getCurrentTeam) ? this.getCurrentTeam.emoji : '😶'
        },
    },
    methods: {
        async send() {
            await Api.participants.createOrUpdate({name: this.name, teamId: this.getTeamId})
            this.closeDialog()
        },
    }
})
</script>
