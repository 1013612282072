<template>
    <n-notification-provider placement="bottom-right">
        <loading-spinner v-if="isLoading"></loading-spinner>
        <div
            id="app"
            :class="{loading: isLoading}"
        >
            <n-page-header class="p-6">
                <template #title>
                    {{ teamName }} Quiz - Scoreboard
                </template>
                <template #avatar>
                    <n-avatar class="team-avatar" :style="{background: teamColor}">
                        {{ teamEmoji }}
                    </n-avatar>
                </template>
                <template #extra>
                    <n-space v-if="isAdminMode === true">
                        <n-button
                            large
                            type="info"
                            @click="openQuestionDialog"
                        >
                            <template #icon>
                                <n-icon>
                                    <add-circle-icon/>
                                </n-icon>
                            </template>
                            Fragerunde hinzufügen
                        </n-button>
                    </n-space>
                </template>
            </n-page-header>
            <n-space vertical>
                <n-layout v-if="getCurrentTeam">
                    <n-layout has-sider>
                        <n-layout-sider
                            bordered
                            show-trigger
                            collapse-mode="width"
                            :collapsed-width="64"
                            :width="300"
                            :native-scrollbar="false"
                            style="max-height: 320px;"
                        >
                            <n-menu

                                @update:value="handleMenuSelection"
                                :collapsed-width="48"
                                :collapsed-icon-size="24"
                                :icon-size="24"
                                :options="menuOptions"
                            />
                        </n-layout-sider>
                        <n-layout class="router-view">
                            <router-view
                                v-on:open-question-dialog="openQuestionDialog"
                                :key="$route.fullPath">
                            </router-view>
                        </n-layout>
                    </n-layout>
                    <n-layout-footer
                        bordered
                        class="p-6"
                    >
                        made with ❤️
                    </n-layout-footer>
                </n-layout>
                <n-layout v-else>
                    <n-alert title="Bitte Team auswählen!" type="warning">
                        <p>Es muss ein Team ausgewählt werden.</p>
                        <p>Wenn kein Dialog angezeigt wurde, um ein Team auszuwählen, klicke bitte <a href="#"
                                                                                                      v-on:click="openTeamDialog">hier</a>.
                        </p>

                    </n-alert>
                </n-layout>
            </n-space>
            <participant-dialog
                v-if="openedDialog === 'participantDialog'"
                :opened-dialog="openedDialog"
                @reset-dialog="resetDialog"
            />
            <team-dialog
                v-if="openedDialog === 'teamDialog'"
                :opened-dialog="openedDialog"
                @reset-dialog="resetDialog"
            />
            <add-question-dialog
                v-if="openedDialog === 'addQuestionDialog'"
                :opened-dialog="openedDialog"
                @reset-dialog="resetDialog"
            />
            <error-dialog
                v-if="getError"
                :error="getError"
                @reset-error="resetError"
            />
        </div>
    </n-notification-provider>
</template>

<script>
import {h, defineComponent, ref} from "vue"
import {mapGetters, mapMutations, mapActions} from "vuex"

import {
    NNotificationProvider,
    NButton,
    NIcon,
    NSpace,
    NLayout,
    NLayoutHeader,
    NLayoutSider,
    NLayoutFooter,
    NMenu,
    NSwitch,
    NPageHeader,
    NAvatar,
    NAlert,
    NBreadcrumb,
    NBreadcrumbItem,
} from "naive-ui"

import {
    AddCircle as AddCircleIcon,
    Trophy as TrophyIcon,
    PersonAdd as PersonAddIcon,
    List as ListIcon,
    Accessibility as AccessibilityIcon,
    TrophyOutline as TrophyOutlineIcon,
} from "@vicons/ionicons5"

import LoadingSpinner from "./components/LoadingSpinner"
import ParticipantDialog from "./components/dialogs/ParticipantDialog"
import TeamDialog from "./components/dialogs/TeamDialog"
import AddQuestionDialog from "./components/dialogs/AddQuestionDialog"
import ErrorDialog from "./components/dialogs/ErrorDialog"

import Api from "../api/Api"

function renderIcon(icon) {
    return () => h(NIcon, null, {default: () => h(icon)})
}

export default defineComponent({
    setup() {
        let openedDialog = ref("")
        return {
            openedDialog,
        }
    },
    components: {
        NNotificationProvider,
        NSpace,
        NIcon,
        NLayout,
        NLayoutSider,
        NLayoutFooter,
        NMenu,
        NSwitch,
        NLayoutHeader,
        NPageHeader,
        NBreadcrumb,
        NBreadcrumbItem,
        NAvatar,
        NAlert,
        NButton,
        AddCircleIcon,
        LoadingSpinner,
        ParticipantDialog,
        TeamDialog,
        AddQuestionDialog,
        ErrorDialog,
    },
    computed: {
        ...mapGetters(["isLoading", "getError", "getYears", "getTeams", "getCurrentTeam", "isAdminMode", "getTeamId", "getInitLoadingDone"]),
        menuOptions() {
            const years = []

            if (this.getYears.length) {
                this.getYears.forEach((year) => {
                    years.push({
                        label: year,
                        key: year,
                        route: `/team/${this.teamSlug}/quiz/year/${year}`,
                    })
                })
            }

            const menu = [
                {
                    label: "aktuelle Runde",
                    key: "current-quiz",
                    icon: renderIcon(TrophyIcon),
                    route: `/team/${this.teamSlug}/quiz`,
                },
                {
                    label: "alle Quizrunden",
                    key: "past-quizes",
                    icon: renderIcon(TrophyOutlineIcon),
                    children: years,
                },
                {
                    label: this.teamPersons,
                    key: "show-participants",
                    icon: renderIcon(AccessibilityIcon),
                    children: [
                        {
                            label: "alle zeigen",
                            key: "show-participants",
                            icon: renderIcon(ListIcon),
                            route: `/team/${this.teamSlug}/members`,
                        },
                    ],
                },
            ]

            if (this.isAdminMode) {
                menu[2].children.push({
                    label: this.teamPerson + " hinzufügen",
                    key: "add-participant",
                    icon: renderIcon(PersonAddIcon),
                    dialog: "participantDialog",
                })
            }

            return menu
        },
        teamName() {
            return (this.getCurrentTeam) ? this.getCurrentTeam.name : 'unknown'
        },
        teamPersons() {
            return (this.getCurrentTeam) ? this.getCurrentTeam.persons : 'unknown persons'
        },
        teamPerson() {
            return (this.getCurrentTeam) ? this.getCurrentTeam.person_single : 'unknown person'
        },
        teamEmoji() {
            return (this.getCurrentTeam) ? this.getCurrentTeam.emoji : '❔'
        },
        teamColor() {
            return (this.getCurrentTeam) ? this.getCurrentTeam.color : '#000'
        },
        teamSlug() {
            return (this.getCurrentTeam) ? this.getCurrentTeam.slug : null
        },
    },
    methods: {
        ...mapMutations(["setLoading", "setError", "resetEditQuestionObject", "setTeamId", "setInitLoadingDone"]),
        ...mapActions(["getTeamInfo"]),
        openQuestionDialog() {
            this.openedDialog = "addQuestionDialog"
        },
        openTeamDialog() {
            this.openedDialog = "teamDialog"
        },
        handleMenuSelection(key, item) {
            if (item.dialog) {
                this.openedDialog = item.dialog
            } else if (item.route) {
                this.$router.push(item.route)
            }
        },
        resetDialog() {
            this.resetEditQuestionObject()
            this.openedDialog = null
        },
        resetError() {
            this.setError(null)
        },
    },
    mounted() {
        ;(async () => {
            await Api.admin.config()
            await Api.teams.getAll()

            if (this.$route && this.$route.params && this.$route.params.slug) {
                const teams = this.getTeams.filter((team) => {
                    return team.slug === this.$route.params.slug
                })
                if (teams.length === 1) {
                    this.setTeamId(teams[0].id)
                    if (this.getInitLoadingDone === false) {
                        this.getTeamInfo()
                        this.setInitLoadingDone(true)
                    }
                }
            }

            if (this.getTeamId === null || this.getTeamId === undefined) {
                this.openTeamDialog()
            }

        })()
    },
})
</script>

<style lang="scss">
#app {
    transition: all 0.5s;

    .loading {
        filter: blur(10px);
    }
}

.router-view {
    padding-left: 10px;
}

.team-avatar {
    span {
        transform: translateX(-50%) translateY(-50%) scale(1.5) !important;
    }
}
</style>
