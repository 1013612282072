<script>
import { defineComponent } from "vue"

export default defineComponent({
    beforeMount() {
        this.keypressEvent = (e) => {
            if (e.keyCode === 27) {
                this.closeDialog()
            }
        }
        window.addEventListener("keydown", this.keypressEvent)
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.keypressEvent)
    },
})
</script>
<style lang="scss">
.hgroup {
    h2 {
        margin-bottom: 0;

        & + h3 {
            margin-top: 0;
            font-weight: normal;
        }
    }
}

table {
    th {
        vertical-align: top;
    }
}
</style>
