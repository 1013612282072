<template>
    <participant-list :active-only="false"></participant-list>
</template>

<script>
import { defineComponent } from 'vue'

import ParticipantList from "./components/ParticipantList";

export default defineComponent({
    components: {
        ParticipantList
    }
})
</script>
