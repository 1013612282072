import {createStore} from 'vuex'

import Api from "../api/Api"

const store = createStore({
    state() {
        return {
            isAdmin: false,
            loading: true,
            error: null,
            teamId: null,
            teams: [],
            participants: [],
            years: [],
            quizzes: {},
            initLoadingDone: false,
            editQuestionObject: null
        }
    },
    mutations: {
        setIsAdmin(state, isAdmin) {
            state.isAdmin = isAdmin
        },
        setInitLoadingDone(state, initLoadingDone) {
            state.initLoadingDone = initLoadingDone
        },
        setTeamId(state, teamId) {
            state.teamId = teamId
        },
        setEditQuestionObject(state, question) {
            state.editQuestionObject = question
        },
        resetEditQuestionObject(state) {
            state.editQuestionObject = null
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setParticipants(state, participants) {
            state.participants = participants
        },
        setTeams(state, teams) {
            state.teams = teams
        },
        setError(state, error) {
            state.error = error
        },
        setQuiz(state, quiz) {
            state.quizzes[quiz.year] = quiz
        },
        setYears(state, years) {
            state.years = years
        }
    },
    actions: {
        async getTeamInfo(context) {
            await Api.participants.getAllByTeam(context.state.teamId)
            await Api.quizzes.getCurrentByTeamId(context.state.teamId)
            await Api.quizzes.getYearsByTeamId(context.state.teamId)
        }
    },
    getters: {
        getEditQuestionObject(state) {
            return state.editQuestionObject
        },
        isLoading(state) {
            return state.loading === true
        },
        getActiveParticipants(state) {
            return state.participants.filter((item) => item.active === 1)
        },
        getAllParticipants(state) {
            return state.participants
        },
        findParticipantById: (state) => (id) => {
            const participant = state.participants.filter((item) => item.id === id)
            return (participant.length === 1) ? participant[0] : null
        },
        getError(state) {
            return state.error
        },
        getYears(state) {
            return state.years
        },
        getQuiz: (state) => (year) => {
            if (state.quizzes[year]) {
                return state.quizzes[year]
            }
            return null
        },
        getAllQuizzes(state) {
            return state.quizzes
        },
        getCurrentQuiz(state) {
            let quiz = null
            Object.keys(state.quizzes).forEach((year) => {
                if (state.quizzes[year] && state.quizzes[year].isCurrent) {
                    quiz = state.quizzes[year]
                }
            })
            return quiz
        },
        getTeams(state) {
            return state.teams
        },
        getCurrentTeam(state) {
            const selectedTeams = state.teams.filter((team) => {
                return team.id === state.teamId
            })
            return (selectedTeams.length === 1) ? selectedTeams[0] : null
        },
        getTeamId(state) {
            return state.teamId
        },
        isAdminMode(state, getters) {
            return getters.getCurrentTeam !== null && getters.getCurrentTeam !== undefined && state.isAdmin === true
        },
        getInitLoadingDone(state) {
            return state.initLoadingDone
        }
    }
})

export default store
