<template>
    <n-space vertical>
        <n-table :bordered="false" :single-line="false" size="small">
            <thead>
            <tr>
                <th>Name</th>
                <th>Fragen gestellt</th>
                <th>Punkte total</th>
                <th v-if="this.activeOnly === false">Aktiv</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="participant in getParticipants"
                :key="participant.name"
            >
                <td>
                    {{ participant.name }}
                </td>
                <td> {{ getHostEvents(participant.id) }}</td>
                <td> {{ getScore(participant.id) }}</td>
                <td v-if="this.activeOnly === false">
                    {{ (participant.active === 1) ? '✅' : '❌' }}
                </td>
            </tr>
            </tbody>
        </n-table>
    </n-space>
</template>
<script>
import {defineComponent} from "vue"
import {NSpace, NTable} from "naive-ui"
import {mapGetters} from "vuex";

export default defineComponent({
    setup() {
    },
    components: {
        NSpace,
        NTable,
    },
    props: {
        activeOnly: {
            required: false,
            default: true
        }
    },
    mounted() {
    },
    computed: {
        ...mapGetters(['getAllParticipants', 'getActiveParticipants', 'getAllQuizzes']),
        getParticipants() {
            return (this.activeOnly === true) ? this.getActiveParticipants : this.getAllParticipants
        },
        getParticipantData() {
            let data = {}
            if (this.getAllQuizzes && Object.keys(this.getAllQuizzes).length) {
                Object.keys(this.getAllQuizzes).forEach((key) => {
                    const quiz = this.getAllQuizzes[key]
                    if (quiz && quiz.hosts && quiz.participants.length) {
                        quiz.participants.forEach((host) => {
                            if (data[host.id]) {
                                data[host.id].hostsEvents += host.hostEvents.length
                                data[host.id].score += host.totalScore
                            } else {
                                data[host.id] = {
                                    hostsEvents: host.hostEvents.length,
                                    score: host.totalScore,
                                }
                            }
                        })
                    }
                })
            }
            return data
        }
    },
    methods: {
        getScore(pId) {
            if (this.getParticipantData[pId]) {
                return this.getParticipantData[pId].score
            } else {
                return 'n/a'
            }
        },
        getHostEvents(pId) {
            if (this.getParticipantData[pId]) {
                return this.getParticipantData[pId].hostsEvents
            } else {
                return 'n/a'
            }
        }
    }
})
</script>
