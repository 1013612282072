<template>
    <n-modal
        :show="true"
        :mask-closable="false"
        preset="dialog"
        :closable="false"
        type="error"
    >
        <template #header>
            <h2 class="mb-0 mt-0">Fehler! 😱</h2>
        </template>
        <template #default>
            <n-space class="pb-4" vertical>
                <p>Upsi! Da ist etwas schief gelaufen. Beim vorherigen Request kam es zu einem Fehler.<br/><br/>Details:
                </p>
                <n-code :code="error" language="javascript" :hljs="hljs"/>
            </n-space>
        </template>

        <template #action>
            <n-grid x-gap="12" :cols="4">
                <n-gi span="2" offset="2">
                    <n-button large type="error" block @click="closeDialog">
                        OK
                    </n-button>
                </n-gi>
            </n-grid>
        </template>
    </n-modal>
</template>
<script>
import {defineComponent, getCurrentInstance} from "vue"
import {NModal, NButton, NGrid, NGi, NSpace, NCode} from "naive-ui"

import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'

import AbstractNaiveUiDialog from "./AbstractNaiveUiDialog";

hljs.registerLanguage('javascript', javascript);

export default defineComponent({
    extends: AbstractNaiveUiDialog,
    setup(props) {
        const {emit} = getCurrentInstance()
        return {
            hljs,
            keypressEvent: null,
            closeDialog() {
                emit('resetError')
            },
        }
    },
    props: {
        error: {
            type: String,
            required: true,
        }
    },
    components: {
        NModal,
        NButton,
        NGrid,
        NGi,
        NSpace,
        NCode,
    },
})
</script>
