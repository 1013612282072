<template>
    <quiz-view></quiz-view>
</template>

<script>
import { defineComponent } from "vue"

import QuizView from "./QuizView.vue"

export default defineComponent({
    components: {
        QuizView,
    },
})
</script>
