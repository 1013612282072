<template>
    <tr>
        <td class="no-border-bottom">{{ date }}</td>
        <td class="no-border-bottom">{{ question.question }}</td>
        <td class="no-border-bottom pre-line">{{ question.answer }}</td>
        <td class="no-border-bottom">{{ question.host.name }}</td>
        <td class="no-border-bottom">
            <n-icon size="24" class="action-icon">
                <pencil-icon @click="openQuestionDialog"/>
            </n-icon>
        </td>
    </tr>
    <tr>
        <td
            colspan="5"
            class="sibling-bottom"
        >
            <a class="details-link" href="#" v-on:click.prevent="toggleShowScore">{{ detailsWording }} Details</a>

            <div v-if="showScore">
                <n-table
                    class="score-details-table"
                    striped
                    v-if="question.participations && question.participations.length"
                >
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Total Score</th>
                        <th>Question Score</th>
                        <th>Extra Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="participation in question.participations"
                        :key="participation.person.id"
                    >
                        <td class="column__participant">{{ participation.person.name }}</td>
                        <td>
                            {{ participation.totalScore }}
                        </td>
                        <td>
                            {{ participation.score }}
                        </td>
                        <td>
                            {{ participation.extraScore }}
                        </td>
                    </tr>
                    </tbody>
                </n-table>

            </div>

        </td>
    </tr>
</template>

<script>
import {defineComponent, ref} from "vue"
import {mapMutations} from "vuex"
import {DateTime} from "luxon"
import {
    NTable,
    NCollapse,
    NCollapseItem,
    NIcon,
    NSwitch,
    NSpace,
} from "naive-ui"

import {Pencil as PencilIcon} from "@vicons/ionicons5"

export default defineComponent({
    emits: ['openQuestionDialog'],
    setup() {
        let showScore = ref(false)

        return {
            showScore,
        }
    },
    components: {
        NTable,
        NCollapse,
        NCollapseItem,
        NIcon,
        NSpace,
        NSwitch,
        PencilIcon,
    },
    props: {
        question: {
            required: true,
        },
    },
    computed: {
        date() {
            return DateTime.fromISO(this.question.date)
                .setLocale("de-DE")
                .toLocaleString()
        },
        detailsWording() {
            return (this.showScore) ? 'Verstecke' : 'Zeige'
        }
    },
    methods: {
        ...mapMutations(["setEditQuestionObject"]),
        openQuestionDialog() {
            this.setEditQuestionObject(this.question)
            this.$emit("openQuestionDialog")
        },
        toggleShowScore() {
            this.showScore = !this.showScore
        }
    },
})
</script>

<style lang="scss" scoped>
.row-alike {
    display: flex;
}

.column-alike {
    padding: var(--n-th-padding);

    &.column__date,
    &.column__host,
    &.column__tools {
        // width: calc(10% - (var(--n-th-padding) / 2));
    }

    &.column__question,
    &.column__answer {
        width: calc(35%);
    }

    &:first-of-type {
    }
}

td {
    vertical-align: top;
}

.seamless-td {
    padding: 0 !important;
}

.details-link {
    display: block;
}

.no-border-bottom {
    border-bottom: none;
    padding-bottom: 0;
}

.sibling-bottom {
    padding-top: 0;
}

.score-details-table {
    margin-top: 10px;
}

.action-icon {
    cursor: pointer;
}

.pre-line {
    white-space: pre-line;
}
</style>
