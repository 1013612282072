// General Font
import 'vfonts/Lato.css'
// Monospace Font
import 'vfonts/FiraCode.css'
import 'tailwindcss/tailwind.css'

import { createApp, Vue } from 'vue';
import App from './views/App'
import Router from './router/Routes'
import Store from './store/Store'

const app = createApp(App)

const root = app.use(Router)
    .use(Store)
    .mount('#app')

if (process.env.MIX_ENV_MODE !== 'production') {
    app.config.devtools = true
}
