const LocalStorageHelper = {
    items: {
      lastHost: 'lastHost'
    },
    set: (name, value) => {
        window.localStorage.setItem(name, value)
        console.info(`[LocalStorageHelper]: stored item with key "${name}" and value "${value}"`)
    },
    get: (name) => {
        const item = window.localStorage.getItem(name)
        if (item === null) {
            console.warn(`[LocalStorageHelper]: no localStorage item found by key "${name}"`)
        }
        return item
    },
    delete: (name) => {
        window.localStorage.removeItem(name)
        console.info(`[LocalStorageHelper]: removed possible item by key "${name}"`)
    }
}


export default LocalStorageHelper
