import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home'
import Team from '../views/Team'
import QuizView from '../views/QuizView'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/team/:slug/members',
        name: 'team',
        component: Team
    },
    {
        path: '/team/:slug/quiz',
        name: 'quiz',
        component: QuizView
    },
    {
        path: '/team/:slug/quiz/year/:year/:tab?',
        name: 'quizWithYear',
        component: QuizView
    },
    {
        path: '/team/:slug/quiz/:tab?',
        name: 'quizWithoutYear',
        component: QuizView
    }
];

export default createRouter({
    history: createWebHistory(),
    routes
})
