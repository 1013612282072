<template>
    <n-modal
        :show="true"
        :mask-closable="false"
        preset="dialog"
        :show-icon="false"
        :closable="false"
        class="add-question-dialog"
    >
        <template #header>
            <h2 class="mb-0 mt-0">neue Frage hinzufügen</h2>
        </template>
        <template #default>
            <n-grid
                x-gap="12"
                :cols="3"
            >
                <n-gi>
                    <n-space vertical>
                        <n-steps
                            vertical
                            :current="currentStep"
                            class="steps"
                        >
                            <n-step
                                v-for="item in steps"
                                :key="item.title"
                                :title="item.title"
                                :description="item.description"
                            />
                        </n-steps>
                    </n-space>
                </n-gi>
                <n-gi span="2">
                    <n-space
                        class="pb-4"
                        vertical
                        v-if="currentStep === 1"
                    >
                        <h2>Wer hat die Frage gestellt?</h2>
                        <n-select
                            placeholder="Wer hat die Frage gestellt?"
                            :options="possibleHostOptions"
                            v-model:value="host"
                            @update:value="handleHostUpdate"
                            class="select--large"
                        />

                        <h3>An welchem Tag wurde die Frage gestellt?</h3>

                        <n-grid
                            x-gap="12"
                            :cols="2"
                        >
                            <n-gi>
                                <n-alert
                                    title="Gewählter Tag"
                                    type="default"
                                >
                                    <template #icon>
                                        <n-icon>
                                            <time-icon/>
                                        </n-icon>
                                    </template>
                                    {{ printableTimestamp }}
                                </n-alert>
                            </n-gi>
                            <n-gi>
                                <n-alert
                                    title="Tag wählen"
                                    type="default"
                                >
                                    <template #icon>
                                        <n-icon>
                                            <help-outline-icon/>
                                        </n-icon>
                                    </template>
                                    <n-date-picker
                                        v-model:value="dateTimestamp"
                                        :first-day-of-week="0"
                                        type="date"
                                    />
                                </n-alert>
                            </n-gi>
                        </n-grid>
                    </n-space>
                    <n-space
                        class="pb-4"
                        vertical
                        v-if="currentStep === 2"
                    >
                        <h2>Frage / Antwort</h2>
                        <n-input-group>
                            <n-input-group-label>❓</n-input-group-label>
                            <n-input
                                v-model:value="question"
                                type="text"
                                placeholder="Frage"
                                block
                            />
                        </n-input-group>
                        <n-input-group>
                            <n-input-group-label>💡</n-input-group-label>
                            <n-input
                                v-model:value="answer"
                                type="textarea"
                                placeholder="Antwort"
                                block
                                :autosize="{ minRows: 3, maxRows: 5 }"
                            />
                        </n-input-group>
                    </n-space>
                    <n-space
                        class="pb-4"
                        vertical
                        v-if="currentStep === 3"
                    >
                        <div class="hgroup">
                            <h2>
                                Wer hat die Frage
                                <n-gradient-text type="success">richtig</n-gradient-text>
                                beantwortet?
                            </h2>
                            <h3>(Alle hier genannten Personenn bekommen 1 Punkt.)</h3>
                        </div>
                        <n-select
                            v-model:value="correctAnswers"
                            multiple
                            filterable
                            :options="playerOptions"
                        />
                    </n-space>
                    <n-space
                        class="pb-4"
                        vertical
                        v-if="currentStep === 4"
                    >
                        <div class="hgroup">
                            <h2>Gab es Extra-Punkte in dieser Runde?</h2>
                            <h3 class="font-normal">
                                Zum Beispiel Minuspunkte/Strafpunkte oder Extra-Punkte, für ganz
                                besonders gutes Wissen?
                            </h3>
                        </div>

                        <n-switch
                            size="large"
                            v-model:value="extraScoreSwitch"
                        >
                            <template #checked> Ja, es gab Extra-Punkte!</template>
                            <template #unchecked> Nein, es gab keine Extra-Punkte.</template>
                        </n-switch>
                        <div v-if="extraScoreSwitch === true">
                            <h3>Extra-Punkte</h3>
                            <n-table
                                :bordered="false"
                                :single-line="false"
                            >
                                <tr>
                                    <th>Name</th>
                                    <th>Extra-Punkte</th>
                                    <th>total</th>
                                </tr>
                                <tr
                                    v-for="player in allPlayersExceptHost"
                                    :key="player.id"
                                >
                                    <td class="name-td">
                                        {{ player.name }}
                                    </td>
                                    <td class="point-td">
                                        <n-input-number v-model:value="extraScore[player.id]"/>
                                    </td>
                                    <td>
                                        {{ computedScoreForUser(player.id) }}
                                    </td>
                                </tr>
                            </n-table>
                        </div>
                    </n-space>
                    <n-space
                        class="pb-4"
                        vertical
                        v-if="currentStep === 5"
                    >
                        <h2>Zusammenfassung</h2>
                        <n-table
                            :bordered="false"
                            :single-line="false"
                        >
                            <tr>
                                <th>Datum:</th>
                                <td>{{ printableTimestamp }}</td>
                            </tr>
                            <tr>
                                <th>Host:</th>
                                <td>{{ playerNameById(host) }}</td>
                            </tr>
                            <tr>
                                <th>Frage:</th>
                                <td> {{ question }}</td>
                            </tr>
                            <tr>
                                <th>Antwort:</th>
                                <td class="pre-line"> {{ answer }}</td>
                            </tr>
                            <tr>
                                <th>Korrekte Antwort von:</th>
                                <td>
                                    <div v-if="correctAnswers.length">
                                        <ul>
                                            <li
                                                v-for="pid in correctAnswers"
                                                :key="pid"
                                            >
                                                {{ playerNameById(pid) }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Extrapunkte</th>
                                <td>
                                    <div v-if="Object.keys(extraScore).length">
                                        <ul>
                                            <li
                                                v-for="pid in Object.keys(extraScore)"
                                                :key="pid"
                                            >
                                                {{ playerNameById(pid) }}: {{ extraScore[pid] }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Punkte diese Runde</th>
                                <td>
                                    <div v-if="Object.keys(allPlayerWithAScore).length">
                                        <ul>
                                            <li
                                                v-for="user in allPlayerWithAScore"
                                                :key="user.id"
                                            >
                                                {{ user.name }}: {{ user.score }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </n-table>
                    </n-space>
                </n-gi>
            </n-grid>
            <n-divider/>
            <n-grid
                x-gap="12"
                :cols="3"
            >
                <n-gi
                    span="1"
                    offset="1"
                >
                    <n-button
                        large
                        type="default"
                        :disabled="prevButtonIsClickable === false"
                        @click="handlePreviousStepAction"
                    >
                        <template #icon>
                            <n-icon>
                                <arrow-back-circle-sharp-icon/>
                            </n-icon>
                        </template>
                        Zurück
                    </n-button>
                </n-gi>
                <n-gi
                    span="1"
                    class="text-right"
                >
                    <n-button
                        large
                        type="default"
                        :disabled="nextButtonIsClickable === false"
                        @click="handleNextStepAction"
                        v-if="currentStep !== 5"
                    >
                        <template #icon>
                            <n-icon>
                                <arrow-forward-circle-sharp-icon/>
                            </n-icon>
                        </template>
                        Weiter
                    </n-button>
                    <n-button
                        large
                        type="success"
                        @click="handleSubmitAction"
                        v-if="currentStep === 5"
                    >
                        <template #icon>
                            <n-icon>
                                <save-icon/>
                            </n-icon>
                        </template>
                        Abschicken
                    </n-button>
                </n-gi>
            </n-grid>
            <n-divider/>
        </template>

        <template #action>
            <n-grid
                x-gap="12"
                :cols="4"
            >
                <n-gi span="1">
                    <n-button
                        large
                        type="default"
                        block
                        @click="closeDialog"
                    >
                        Abbrechen
                    </n-button>
                </n-gi>
                <n-gi
                    span="2"
                    offset="1"
                ></n-gi>
            </n-grid>
        </template>
    </n-modal>
</template>
<script>
import {defineComponent, getCurrentInstance, ref} from "vue"
import {
    NModal,
    NAlert,
    NButton,
    NButtonGroup,
    NIcon,
    NGrid,
    NGi,
    NSpace,
    NStep,
    NSteps,
    NSelect,
    NInput,
    NInputGroup,
    NInputGroupLabel,
    NDivider,
    NGradientText,
    NSwitch,
    NInputNumber,
    NDatePicker,
    NTable,
    useNotification,
} from "naive-ui"
import {
    Save as SaveIcon,
    HelpOutline as HelpOutlineIcon,
    Time as TimeIcon,
    ArrowForwardCircleSharp as ArrowForwardCircleSharpIcon,
    ArrowBackCircleSharp as ArrowBackCircleSharpIcon,
} from "@vicons/ionicons5"

import {DateTime} from "luxon"
import {mapGetters} from "vuex"

import LocalStorageHelper from "../../../helper/LocalStorage"
import Api from "../../../api/Api"

import AbstractNaiveUiDialog from "./AbstractNaiveUiDialog"

export default defineComponent({
    extends: AbstractNaiveUiDialog,
    setup() {
        const {emit} = getCurrentInstance()
        let host = ref("")
        let dateTimestamp = ref(Date.now())
        let question = ref("")
        let questionId = ref("")
        let answer = ref("")
        let extraScoreSwitch = ref(false)
        let correctAnswers = ref([])
        let extraScore = ref({})
        const currentStep = ref(1)
        const closeDialog = () => {
            emit("resetDialog")
        }
        const notification = useNotification()
        return {
            extraScore,
            notification,
            host,
            dateTimestamp,
            question,
            questionId,
            answer,
            currentStep,
            extraScoreSwitch,
            correctAnswers,
            closeDialog,
            async send() {
                closeDialog()
            },
            handleHostUpdate(hostId) {
                host = hostId
                LocalStorageHelper.set(
                    LocalStorageHelper.items.lastHost,
                    hostId
                )
            },
            steps: [
                {
                    title: "Host",
                    description: "Wer die Frage gestellt hat",
                    error: {
                        title: "Fehler",
                        description:
                            "Bitte gebe an, wer die Frage gestellt hat!",
                    },
                },
                {
                    title: "Frage / Antwort",
                    description: "Angabe der Frage und Antwort",
                    error: {
                        title: "Fehler",
                        description:
                            "Bitte gebe eine Frage und Antwort an!",
                    },
                },
                {
                    title: "Ergebnis",
                    description: "Hat jmd. die Frage beantwortet?",
                    error: {
                        title: "Fehler",
                        description:
                            "Bitte gebe an, wer die Frage beantwortet hat! Dies kann eine als auch mehrere Personen sein!",
                    },
                },
                {
                    title: "Extra-Punkte",
                    description: "Gab es Extra-Punkte?",
                },
                {
                    title: "Zusammenfassung",
                    description: "",
                },
            ],
        }
    },
    props: {
        openedDialog: {
            type: String,
            required: true,
        },
    },
    components: {
        NModal,
        NAlert,
        NInputNumber,
        NButton,
        NButtonGroup,
        NIcon,
        NGrid,
        NGi,
        NSpace,
        NStep,
        NSteps,
        NSelect,
        NInput,
        NInputGroup,
        NInputGroupLabel,
        NDivider,
        NGradientText,
        NSwitch,
        NDatePicker,
        NTable,
        ArrowForwardCircleSharpIcon,
        ArrowBackCircleSharpIcon,
        TimeIcon,
        HelpOutlineIcon,
        SaveIcon,
    },
    beforeMount() {
        const lastHost = LocalStorageHelper.get(
            LocalStorageHelper.items.lastHost
        )
        if (lastHost && this.findParticipantById(lastHost)) {
            this.host = lastHost
        }

        if (this.getEditQuestionObject) {
            const q = this.getEditQuestionObject
            this.questionId = q.id
            this.host = q.host.id
            this.answer = q.answer
            this.question = q.question
            this.currentStep = 3
            this.dateTimestamp = DateTime.fromISO(q.date).toMillis()
            const correctAnswers = []
            const extraScores = {}
            let extraScoreSwitch = false

            if (q.participations && q.participations.length) {
                q.participations.forEach((p) => {
                    if (p.score > 0) {
                        correctAnswers.push(p.person.id)
                    }
                    if (p.extraScore !== 0) {
                        extraScores[p.person.id] = p.extraScore
                    }
                    if (p.isWithExtraScore === true) {
                        extraScoreSwitch = true
                    }
                })
            }

            this.extraScore = extraScores
            this.correctAnswers = correctAnswers
            this.extraScoreSwitch = extraScoreSwitch
        }
    },
    computed: {
        ...mapGetters([
            "getActiveParticipants",
            "findParticipantById",
            "getEditQuestionObject",
            "getTeamId",
        ]),
        possibleHostOptions() {
            const hosts = []
            this.getActiveParticipants.forEach((item) => {
                hosts.push(this.transformParticipantForSelect(item))
            })
            return hosts
        },
        prevButtonIsClickable() {
            return this.currentStep > 1
        },
        nextButtonIsClickable() {
            return this.currentStep !== this.steps.length
        },
        allPlayersExceptHost() {
            return this.getActiveParticipants.filter((item) => {
                return item.id !== this.host
            })
        },
        allPlayerWithAScore() {
            const players = {}
            const getPlayerObjectById = (pId) => {
                const player = this.allPlayersExceptHost.filter((p) => {
                    return p.id === pId
                })
                if (player.length === 1) {
                    return player[0]
                }

                return null
            }

            this.correctAnswers.forEach((pId) => {
                const player = getPlayerObjectById(pId)
                if (player) {
                    players[pId] = player
                }
            })

            Object.keys(this.extraScore).forEach((pId) => {
                if (this.extraScore[pId]) {
                    if (!players[pId]) {
                        const player = getPlayerObjectById(pId)
                        if (player) {
                            players[pId] = player
                        }
                    }
                }
            })

            Object.keys(players).forEach((pId) => {
                players[pId].score = this.computedScoreForUser(pId)
            })

            return players
        },
        playerOptions() {
            return this.allPlayersExceptHost.map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                }
            })
        },
        printableTimestamp() {
            const dt = DateTime.fromMillis(this.dateTimestamp)
            const compareDate = DateTime.now()

            if (dt.hasSame(compareDate, "day")) {
                return "Heute"
            }

            if (dt.hasSame(compareDate.minus({days: 1}), "day")) {
                return "Gestern (" + dt.toLocaleString(DateTime.DATE_MED) + ")"
            }

            return dt.toLocaleString(DateTime.DATE_MED)
        },
        getExtraScoredUsers() {
        },
    },
    methods: {
        transformParticipantForSelect(item) {
            return {
                label: item.name,
                value: item.id,
            }
        },
        handlePreviousStepAction() {
            this.currentStep--
        },
        handleNextStepAction() {
            const checkResult = this.check()

            if (checkResult === true) {
                this.currentStep++
            } else {
                this.openErrorNotification(this.currentStep)
            }
        },
        async handleSubmitAction() {
            let id = null
            if (this.questionId) {
                id = this.questionId
            }

            await Api.questions.createOrUpdate({
                teamId: this.getTeamId,
                host: this.host,
                date: this.dateTimestamp,
                correctAnswers: this.correctAnswers,
                extraScore: this.extraScore,
                answer: this.answer,
                question: this.question,
                id,
            })
            this.closeDialog()
        },
        check() {
            const fncName = "checkStep" + this.currentStep
            return this[fncName]()
        },
        checkStep1() {
            return typeof this.findParticipantById(this.host) === "object"
        },
        checkStep2() {
            return (
                this.answer &&
                this.question &&
                this.answer !== "" &&
                this.question !== ""
            )
        },
        checkStep3() {
            if (this.correctAnswers.length === 0) {
                this.notification.create({
                    title: "Obacht",
                    content:
                        'Du hast angegeben, dass niemand die Frage richtig beantwortet hat. Falls das ein Versehen war, gehe einfach einen Schritt "zurück".',
                    duration: 8000,
                    closable: true,
                    type: "info",
                })
            }
            return true
        },
        checkStep4() {
            return true
        },
        openErrorNotification(stepNumber) {
            const stepDetails = this.steps[stepNumber - 1]
            if (stepDetails && stepDetails.error) {
                this.notification.create({
                    title: stepDetails.error.title,
                    content: stepDetails.error.description,
                    duration: 4000,
                    closable: false,
                    type: "error",
                })
            }
        },
        computedScoreForUser(userId) {
            let score = 0

            if (this.correctAnswers.indexOf(userId) !== -1) {
                score++
            }

            if (this.extraScore[userId]) {
                score += this.extraScore[userId]
            }

            return score
        },
        playerNameById(userId) {
            const player = this.findParticipantById(userId)

            if (player) {
                return player.name
            }

            return ""
        },
    },
})
</script>
<style lang="scss">
.add-question-dialog {
    @media (max-width: 800px) {
        min-width: 100%;
    }
    @media (min-width: 801px) {
        min-width: 800px;
    }
}

.steps {
    border-right: 3px solid rgb(224, 224, 230);

    .n-step {
        padding-left: 10px;
    }
}

.select {
    &--large {
        .n-base-selection-label {
            font-size: 150%;
        }
    }
}

table {
    width: 100%;

    .name-td {
        width: 35%;
        max-width: 35%;
    }

    .point-td {
        width: 30%;
        max-width: 30%;
    }
}

.pre-line {
    white-space: pre-line;
}
</style>
