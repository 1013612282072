<template>
    <n-modal
        :show="true"
        :mask-closable="false"
        preset="dialog"
        :show-icon="false"
        :closable="false"
    >
        <template #header>
            <h2 class="mb-0 mt-0">Team auswählen</h2>
        </template>
        <template #default>
            <n-space class="pb-4" vertical>
                <n-select
                    placeholder="Welches Team möchtest du auswählen?"
                    :options="possibleTeamOptions"
                    v-model:value="teamId"
                    class="select--large"
                />
            </n-space>
        </template>

        <template #action>
            <n-grid x-gap="12" :cols="4">
                <n-gi span="2" offset="1">
                    <n-button large type="success" block @click="select">
                        <template #icon>
                            <n-icon>
                                <bonfire-icon/>
                            </n-icon>
                        </template>
                        Auswählen
                    </n-button>
                </n-gi>
            </n-grid>

        </template>
    </n-modal>
</template>
<script>
import {defineComponent, getCurrentInstance, ref} from "vue"
import {mapGetters, mapMutations} from "vuex"

import {NModal, NButton, NIcon, NGrid, NGi, NSpace, NSelect} from "naive-ui"
import {
    Bonfire as BonfireIcon,
} from '@vicons/ionicons5'

import Api from '../../../api/Api'

import AbstractNaiveUiDialog from "./AbstractNaiveUiDialog";

export default defineComponent({
    extends: AbstractNaiveUiDialog,
    setup(props) {
        const {emit} = getCurrentInstance()
        let teamId = ref("")
        const closeDialog = () => {
            emit('resetDialog')
        }
        return {
            teamId,
            closeDialog
        }
    },
    props: {
        openedDialog: {
            type: String,
            required: true,
        }
    },
    components: {
        NModal,
        NButton,
        NIcon,
        NGrid,
        NGi,
        NSpace,
        NSelect,
        BonfireIcon,
    },
    computed: {
        ...mapGetters(['getTeams', 'getCurrentTeam']),
        possibleTeamOptions() {
            let teams = []
            this.getTeams.forEach((team) => {
                teams.push({
                    label: team.name,
                    value: team.id,
                })
            })
            return teams
        }
    },
    methods: {
        ...mapMutations(["setTeamId"]),
        select() {
            this.setTeamId(this.teamId)
            this.$router.push({
                name: "quiz",
                params: { slug: this.getCurrentTeam.slug },
            })
            this.closeDialog()
        }
    }
})
</script>
