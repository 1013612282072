<template>
    <div v-if="quiz">
        <h1 class="mt-0">Quiz {{ quiz.year }}</h1>

        <n-tabs
            type="segment"
            v-model:value="tab"
        >
            <n-tab-pane
                name="ranking"
                tab="Ranking"
            >
                <n-table striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Punkte</th>
                        </tr>
                    </thead>
                    <tbody v-if="quiz.participants && quiz.participants.length">
                        <tr
                            v-for="participant in quiz.participants"
                            :key="participant.id"
                        >
                            <td>{{ participant.name }}</td>
                            <td>{{ participant.totalScore }}</td>
                        </tr>
                    </tbody>
                </n-table>
            </n-tab-pane>
            <n-tab-pane
                name="questions"
                tab="Fragen"
            >
                <n-table>
                    <thead>
                        <tr>
                            <th class="column__date">Datum</th>
                            <th class="column__question">Frage</th>
                            <th class="column__answer">Antwort</th>
                            <th class="column__host">Host</th>
                            <th class="column__tools">Tools</th>
                        </tr>
                    </thead>
                    <tbody v-if="quiz.questions && quiz.questions.length">
                        <question-item
                            @open-question-dialog="openQuestionDialog"
                            v-for="question in quiz.questions"
                            :key="question.id"
                            :question="question"
                        ></question-item>
                    </tbody>
                </n-table>

            </n-tab-pane>
            <n-tab-pane
                name="stats"
                tab="Stats"
            >
                tbd tbc
            </n-tab-pane>
        </n-tabs>
    </div>
</template>

<script>
import { h, defineComponent, ref } from "vue"
import {mapGetters, mapActions, mapMutations} from "vuex"
import { NTabs, NTabPane, NTable } from "naive-ui"

import QuestionItem from "./components/QuestionItem.vue"
import Api from "../api/Api";

export default defineComponent({
    emits: ['openQuestionDialog'],
    setup() {
        let year = ref("")
        let tab = ref("ranking")
        return {
            year,
            tab,
        }
    },
    components: {
        NTabs,
        NTabPane,
        NTable,
        QuestionItem,
    },

    mounted() {
        if (this.$route && this.$route.params && this.$route.params.year) {
            this.year = this.$route.params.year
        }
        if (this.$route && this.$route.params && this.$route.params.slug) {
            this.slug = this.$route.params.slug
        }
        if (this.$route && this.$route.params && this.$route.params.tab) {
            this.tab = this.$route.params.tab
        }

        ;(async () => {
            if (this.getInitLoadingDone === false) {
                await this.getTeamInfo()
                this.setInitLoadingDone(true)
            }
            if (this.quiz === null && this.year) {
                await Api.quizzes.getQuizByYearAndTeamId(this.year, this.getTeamId)
            }
        })()
    },

    computed: {
        ...mapGetters(["getCurrentQuiz", "getCurrentTeam", "getInitLoadingDone", "getTeamId"]),
        quiz() {
            if (this.year) {
                return this.$store.getters.getQuiz(this.year)
            }

            return this.getCurrentQuiz
        },
    },
    methods: {
        ...mapMutations(['setInitLoadingDone']),
        ...mapActions(['getTeamInfo']),
        openQuestionDialog() {
            this.$emit("openQuestionDialog")
        },
    },

    watch: {
        tab(newValue) {
            const year = this.year ?? this.quiz.year
            const routeName = year ? 'quizWithYear' : 'quizWithoutYear'
            this.$router.push({
                name: routeName,
                params: { tab: this.tab, year: this.year, slug: this.getCurrentTeam.slug },
            })
        },
    },
})
</script>

<style lang="scss">
.column {
    &__question {
        width: 35%;
    }

    &__answer {
        width: 35%;
    }

    &__date {
        width: 10%;
    }

    &__host {
        width: 10%;
    }

    &__tools {
        width: 10%;
    }
}
</style>
