import axios from 'axios'
import Store from '../store/Store'
import LocalStorageHelper from '../helper/LocalStorage'

let lastRequest = null
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['X-Rubberducks-Quiz-LastHost'] = LocalStorageHelper.get(LocalStorageHelper.items.lastHost)
axios.defaults.withCredentials = true
axios.interceptors.request.use(function (config) {
    Store.commit('setLoading', true)
    lastRequest = config
    return config;
}, function (error) {
    /*return Promise.reject(error);*/
});
axios.interceptors.response.use((response) => {
    Store.commit('setLoading', false)
    return response;
}, (error) => {
    if (error && lastRequest && lastRequest.url === '/api/admin-config') {
        return Promise.resolve(); // Promise.reject(error);
    }

    if (error && error.response && error.response.data && error.response.data.error) {
        Store.commit('setError', error.response.data.error)
    }

    if (error && error.response && error.response.status === 500) {
        Store.commit('setError', 'Server issues. Sorry ¯\\_(ツ)_/¯')
    }

    Store.commit('setLoading', false)
    return Promise.reject(error);
});


const Api = {
    admin: {
        async config() {
            return axios.get('/api/admin-config').then(response => {
                if (response && response.status === 200 && response.data) {
                    Store.commit('setIsAdmin', (response.data.check === 'admin'))
                }
            })
        }
    },
    teams: {
        async getAll() {
            return axios.get('/api/teams/all').then(response => {
                if (response.status === 200 && response.data) {
                    Store.commit('setTeams', response.data)
                }
            })
        },
    },
    participants: {
        async getAll() {
            return axios.get('/api/participants/all').then(response => {
                if (response.status === 200 && response.data) {
                    Store.commit('setParticipants', response.data)
                }
            })
        },
        async getAllByTeam(teamId) {
            return axios.get('/api/participants/team/' + teamId).then(response => {
                if (response.status === 200 && response.data) {
                    Store.commit('setParticipants', response.data)
                }
            })
        },
        async createOrUpdate(data) {
            await axios.post('/api/participants/add', data)
            await Api.participants.getAll()
        }
    },
    questions: {
        async createOrUpdate(data) {
            await axios.post('/api/questions/add', data)
            await Api.quizzes.getCurrentByTeamId(data.teamId)
        },
        async getAll() {
            return axios.get('/api/questions/all').then(response => {
                if (response.status === 200 && response.data) {
                    console.log(response.data)
                }
            })
        }
    },
    quizzes: {
        async getAll() {
            return axios.get('/api/quizzes/all').then(response => {
                if (response.status === 200 && response.data) {
                    console.log(response.data)
                }
            })
        },
        async getCurrent() {
            return axios.get('/api/quizzes/current').then(response => {
                if (response.status === 200 && response.data) {
                    Store.commit('setQuiz', response.data)
                }
            })
        },
        async getCurrentByTeamId(teamId) {
            return axios.get('/api/quizzes/current/team/' + teamId).then(response => {
                if (response.status === 200 && response.data) {
                    Store.commit('setQuiz', response.data)
                }
            })
        },
        async getQuizByYearAndTeamId(year, teamId) {
            return axios.get('/api/quizzes/year/' + year + '/team/' + teamId).then(response => {
                if (response.status === 200 && response.data) {
                    Store.commit('setQuiz', response.data)
                }
            })
        },
        async getYears() {
            return axios.get('/api/quizzes/years').then(response => {
                if (response.status === 200 && response.data) {
                    Store.commit('setYears', response.data)
                }
            })
        },
        async getYearsByTeamId(teamId) {
            return axios.get('/api/quizzes/years/team/' + teamId).then(response => {
                if (response.status === 200 && response.data) {
                    Store.commit('setYears', response.data)
                }
            })
        },
    }
}

export default Api
